<template>

    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="variant" class="mr-1" :disabled="disabled || isLoading">
        <template v-if="isLoading"> 
            <b-spinner small />
            &nbsp;
        </template>       
        <slot v-if="!isLoading || !loadMessage"> </slot>
        <span v-else>{{ loadMessage }} </span>        
    </b-button>

</template>

<script>

import { BButton, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    name: 'button-spinner',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        loadMessage: {
            type: String,
            default: null
        },
        variant: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    directives: {
        Ripple,
    },  
    components: {
        BButton,
        BSpinner
    }
}

</script>